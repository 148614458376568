<script lang="ts" setup>
import JivoButton from '~/components/JivoButton.vue';
import TelegramPopup from '~/components/TelegramPopup.vue';
import VFooter from '~/components/VFooter.vue';
import VHeader from '~/components/VHeader.vue';
import { useJivoStore } from '~/stores/JivoStore';
import { entities } from '~/enitityHelper';

const route = useRoute();
const jivoStore = useJivoStore();

const cClass = computed(() => {
  if (!route.name) return 'white';

  if ([...Object.keys(entities), 'index'].includes(route.name?.toString())) {
    return 'transparent';
  }
  if (route.name === 'projects-id') {
    return 'white-line';
  }

  return 'white';
});
</script>

<template>
  <div>
    <v-header :schema="cClass" />
    <main>
      <slot />
    </main>
    <jivo-button
      :bottom-offset="jivoStore.bottomOffset"
      :is-hidden="jivoStore.isHidden"
    />
    <telegram-popup />
    <v-footer />
  </div>
</template>

<style lang="scss"></style>
