<script setup lang="ts">
import { iconColors } from 'assets/js/utils';
import { MessageCircleQuestion } from 'lucide-vue-next';

interface Props {
  bottomOffset: number;
  isHidden: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  bottomOffset: 16,
  isHidden: false,
});

function open() {
  if (window.jivo_api) {
    window.jivo_api.open();
  } else {
    alert(
      'Похоже, что у вас включен блокировщик рекламы, сайт может работать некорректно. Добавьте сайт begemot.ai в исключения или выключите блокировщик рекламы и обновите страницу.'
    );
  }
}
</script>

<template>
  <client-only>
    <button
      class="jivo-button"
      :style="{ bottom: `${props.bottomOffset}px` }"
      :class="{ hidden: props.isHidden }"
      @click="open()"
    >
      <MessageCircleQuestion
        :size="28"
        :color="iconColors.primary"
      />
    </button>
  </client-only>
</template>

<style scoped lang="scss">
.jivo-button {
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  position: fixed;
  transition: opacity 0.2s;
  visibility: visible;
  z-index: 2147483647;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  border: 1px solid $line-stroke;
  background: #fff;
  box-shadow: 0px 4px 26px 0px $line-theme-fade;
  cursor: pointer;
  @include media-breakpoint-up(xl) {
    display: none;
  }

  &.hidden {
    opacity: 0;
  }

  &:focus {
    outline: none;
  }
}
</style>
